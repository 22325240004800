import { defineStore } from "pinia";
import Api from "../services/Api";

export const motionStore = defineStore("motion", {
  state: () => {
    return {
      motion: null,
      motions: [],
      motionsEnacted: [],
      bills: [],
      acts: [],
    };
  },
  actions: {
    getMotions() {
      return Api.getMotions()
        .then((res) => {
          this.motions = res.data.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    getMotionsEnacted() {
      return Api.getMotionsEnacted()
        .then((res) => {
          console.log(res);
          this.motionsEnacted = [];
          // format response
          for (let mt of res.data.data) {
            let obj = {
              id: mt.motion_id,
              title: mt.motion.introduced.title_or_chapter,
              subject: mt.motion.introduced.subject,
              author: mt.motion.author.name,
              committee: mt.committee,
              enacted_date: mt.enacted_date,
              passage_date: mt.updated_at,
              introduction_date: mt.created_at,
              ordinance_no: mt.ordinance_no,
              description: mt.motion.introduced.description,
              status: mt.motion.status,
            };
            this.motionsEnacted.push(obj);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    getBills() {
      return Api.getBills()
        .then((res) => {
          this.bills = [];
          // format response
          for (let mt of res.data.data) {
            let obj = {
              id: mt.motion_id,
              title: mt.motion.introduced.title_or_chapter,
              subject: mt.motion.introduced.subject,
              author: mt.motion.author.name,
              committee: mt.committee,
              enacted_date: mt.motion.enacted.enacted_date,
              passage_date: mt.motion.enacted.updated_at,
              resolution_no: mt.resolution_no,
              description: mt.motion.introduced.description,
              status: mt.motion.status,
              file: mt.file,
            };
            this.bills.push(obj);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    getActs() {
      return Api.getActs()
        .then((res) => {
          this.acts = [];
          // format response
          for (let mt of res.data.data) {
            let obj = {
              id: mt.motion_id,
              act_number: mt.act_no,
              subject: mt.motion.introduced.subject,
              author: mt.motion.author.name,
              committee: mt.committee,
              passage_date: mt.motion.bills.updated_at,
              description: mt.motion.introduced.description,
              status: mt.motion.status,
              file: mt.file,
            };
            this.acts.push(obj);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    createMotion(data) {
      return Api.createMotion(data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    createMotionEnacted(data) {
      return Api.createMotionEnacted(data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    createBill(data) {
      return Api.createBill(data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    createAct(data) {
      return Api.createAct(data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    editMotion(id, data) {
      return Api.editMotion(id, data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    editMotionEnacted(id, data) {
      return Api.editMotionEnacted(id, data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    editBill(id, data) {
      return Api.editBill(id, data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    editAct(id, data) {
      return Api.editAct(id, data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    deleteMotion(id) {
      return Api.deleteMotion(id)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    deleteBill(id) {
      return Api.deleteBill(id)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    deleteAct(id) {
      return Api.deleteAct(id)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
});
