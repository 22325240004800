<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">bills management</div>
      </v-row>
      <v-row class="">
        <div class="">
          Manage bills and mark them as acts when they are signed by the
          president
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <div class="d-flex">
        <v-spacer></v-spacer>

        <v-text-field
          label="Search"
          outlined
          dense
          append-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </div>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="bills"
        class="text-capitalize"
      >
        <template v-slot:[`item.passage_date`]="{ item }">
          {{ item.passage_date | moment("Do MMMM YYYY") }}
        </template>
        <template v-slot:[`item.committee`]="{ item }">
          {{ item.committee == null ? "None" : item.commitee.name }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-btn
              v-if="item.status == 'act'"
              small
              rounded
              color="success"
              text
              outlined
              :ripple="false"
              >passed</v-btn
            >
            <v-btn
              v-else
              small
              rounded
              color="primary"
              text
              outlined
              @click="actCreate(item)"
              >mark as passed</v-btn
            >
          </div>
        </template>
      </v-data-table>

      <v-dialog width="auto" v-model="makeActDialog">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">mark as passed</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <v-form v-model="makeAct" ref="makeAct">
              <v-text-field
                label="Resolution No"
                v-model="actNumber"
                :rules="[rules.required]"
                outlined
                dense
              ></v-text-field>
              <v-file-input
                label="Attach document"
                v-model="actDocument"
                :rules="[rules.required]"
                outlined
                dense
              />
            </v-form>

            <v-btn
              color="primary"
              :disabled="!makeAct"
              :loading="actLoader"
              block
              @click="motionUpdate()"
              >pass bill</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-model="snackBar"
      :color="snackBarData.success ? 'success' : 'error'"
    >
      <div class="text-capitalize text-center">
        {{ snackBarData.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { motionStore } from "@/stores/motions";
import { mapState, mapActions } from "pinia";
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "title/chapter",
          value: "title",
          class: "text-uppercase black--text",
        },
        {
          text: "subject",
          value: "subject",
          class: "text-uppercase black--text",
        },
        {
          text: "author",
          value: "author",
          class: "text-uppercase black--text",
        },
        {
          text: "commitee",
          value: "committee",
          class: "text-uppercase black--text",
        },
        {
          text: "date passed",
          value: "passage_date",
          class: "text-uppercase black--text",
        },
        {
          text: "resolution no.",
          value: "resolution_no",
          class: "text-uppercase black--text",
        },
        {
          text: "description",
          value: "description",
          width: "17%",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      actDocument: null,
      makeActDialog: false,
      actNumber: "",
      addLoader: false,
      addForm: false,
      addDialog: false,
      rules: {
        required: (v) => !!v || "Required",
      },
      makeAct: false,
      actForm: false,
      actLoader: false,
      snackBar: false,
      snackBarData: {
        success: false,
        message: "",
      },
      activeItemId: null,
    };
  },
  computed: {
    ...mapState(motionStore, ["bills"]),
  },
  methods: {
    ...mapActions(motionStore, ["getBills", "createAct"]),
    actCreate(item) {
      this.activeItemId = item.id;
      this.makeActDialog = true;
    },
    motionUpdate() {
      this.actLoader = true;

      let formData = new FormData();
      formData.append("motion_id", this.activeItemId);
      formData.append("status", "act");
      formData.append("act_no", this.actNumber);
      formData.append("file_type", "act");
      formData.append("file", this.actDocument);
      this.createAct(formData)
        .then(() => {
          this.showSnackBar(true, "act created succesfully");
          this.getBills();
          this.$refs.makeAct.reset();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.actLoader = false;
          this.makeActDialog = false;
        });
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
  },
  mounted() {
    this.getBills();
  },
};
</script>

<style></style>
